<template>
    <div class="card">
        <header class="card-header">
          <h2 class="card-text">
            {{ this.metricas.pedidos_transmitidos.titulo }}
            <toggle-button
              class="float-right"
              v-if="show_toggle"
              @change="handleTipoGrafico"
              :value="quantitativo"
              :sync="true"
              :labels="{
                checked: 'Quantidades',
                unchecked: 'Valores',
              }"
              :color="{
                checked: '#7DCE94',
                unchecked: '#39f',
                disabled: '#CCCCCC',
              }"
              :width="100"
            />
          </h2>
        </header>
        <div class="card-body">
          <div class="row">
            <div class="col-3-4">
              <CChartLine
                style="height:300px"
                :datasets="datasets"
                :labels="labels"
                :options="options"
              />
            </div>
            <div class="col-1-4">
              <CardBorderWidget
                :title="`${percentual_b2b} via B2B`"
                :value="totais_b2b"
                icon="globe-americas"
                color="info"
              />
              <CardBorderWidget
                :title="`${percentual_callcenter} via Callcenter`"
                :value="totais_callcenter"
                icon="headset"
                color="warning"
              />
              <CardBorderWidget
                :title="`${percentual_forca_vendas} via FV`"
                :value="totais_forca_vendas"
                icon="user-tie"
                color="primary"
              />
            </div>
          </div>
        </div>
    </div>
</template>

<script>
  import { CChartLine } from '@coreui/vue-chartjs'
  import CardInfoWidget from '../../../components/charts/CardInfoWidget';
  import CardBorderWidget from '../../../components/charts/CardBorderWidget';
  import _sumBy from 'lodash/sumBy';
  import {toMonetary, toPercent} from "@/helpers/utils";

  export default {
    name: "PedidosTransmitidos",
    components: {
      CChartLine,
      CardInfoWidget,
      CardBorderWidget
    },
    props: [
      'metricas',
      'colaborador'
    ],
    data() {
      return {
        quantitativo: true,
      }
    },
    computed: {
      show_toggle() {
        return ['SUPERVISOR', 'ADMIN', 'GERENTE'].indexOf(this.colaborador.tipo) >= 0;
      },
      campo_b2b() {
        return this.quantitativo ? 'qtd_pedidos_b2b' : 'total_pedidos_b2b'
      },
      campo_callcenter() {
        return this.quantitativo ? 'qtd_pedidos_callcenter' : 'total_pedidos_callcenter'
      },
      campo_forca_vendas() {
        return this.quantitativo ? 'qtd_pedidos_forca_vendas' : 'total_pedidos_forca_vendas'
      },
      total_transmitido() {
        let total_b2b         = +_sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_b2b);
        let total_callcenter  = +_sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_callcenter);
        let total_fv          = +_sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_forca_vendas);
        return total_b2b + total_callcenter + total_fv;
      },
      total_b2b () {
        return _sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_b2b) || .0
      },
      total_callcenter () {
        return _sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_callcenter) || .0
      },
      total_forca_vendas () {
        return _sumBy(this.metricas?.pedidos_transmitidos?.metricas, this.campo_forca_vendas) || .0
      },
      totais_b2b () {
        return this.quantitativo ? `${this.total_b2b} pedido(s)` : toMonetary(this.total_b2b);
      },
      totais_callcenter () {
        return this.quantitativo ? `${this.total_callcenter} pedido(s)` : toMonetary(this.total_callcenter);
      },
      totais_forca_vendas () {
        return this.quantitativo ? `${this.total_forca_vendas} pedido(s)` : toMonetary(this.total_forca_vendas);
      },
      percentual_b2b () {
        let total = this.total_transmitido
        return toPercent( total > 0
          ? (this.total_b2b / total) * 100
          : 0, 6);
      },
      percentual_callcenter () {
        let total = this.total_transmitido
        return toPercent( total > 0
          ? (this.total_callcenter / total) * 100
          : 0, 6);
      },
      percentual_forca_vendas () {
        let total = this.total_transmitido
        return toPercent( total > 0
          ? (this.total_forca_vendas / total) * 100
          : 0, 6);
      },
      labels() {
        return this.metricas.pedidos_transmitidos.metricas.reduce((acc, metrica) => {
          acc.push( metrica.data_formatada );
          return acc;
        }, []);
      },
      datasets() {
        return [
          this.getMetrica('Pedidos B2B', this.campo_b2b, '#39f'),
          this.getMetrica('Pedidos CallCenter', this.campo_callcenter, '#f9b115'),
          this.getMetrica('Pedidos Força de Vendas', this.campo_forca_vendas, '#004576'),
        ]
      },
      options() {
        return {
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              display: true
            }],
              yAxes: [{
              display: true
            }]
          },
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 4,
                hitRadius: 10,
                hoverRadius: 4
            }
          }
        };
      },
    },
    methods: {
      handleTipoGrafico() {
        this.quantitativo = !this.quantitativo;
      },
      getMetrica(titulo, campo, cor, tensao) {
        return {
          label: titulo,
          data: this.metricas.pedidos_transmitidos.metricas.reduce((acc, metrica) => {
            acc.push( metrica[campo] );
            return acc;
          }, []),
          fill: true,
          borderColor: cor,
          tension: 0.1
        };
      }
    }
  }
</script>
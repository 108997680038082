var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('h2',{staticClass:"card-text"},[_vm._v(" "+_vm._s(this.metricas.pedidos_transmitidos.titulo)+" "),(_vm.show_toggle)?_c('toggle-button',{staticClass:"float-right",attrs:{"value":_vm.quantitativo,"sync":true,"labels":{
            checked: 'Quantidades',
            unchecked: 'Valores',
          },"color":{
            checked: '#7DCE94',
            unchecked: '#39f',
            disabled: '#CCCCCC',
          },"width":100},on:{"change":_vm.handleTipoGrafico}}):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3-4"},[_c('CChartLine',{staticStyle:{"height":"300px"},attrs:{"datasets":_vm.datasets,"labels":_vm.labels,"options":_vm.options}})],1),_c('div',{staticClass:"col-1-4"},[_c('CardBorderWidget',{attrs:{"title":(_vm.percentual_b2b + " via B2B"),"value":_vm.totais_b2b,"icon":"globe-americas","color":"info"}}),_c('CardBorderWidget',{attrs:{"title":(_vm.percentual_callcenter + " via Callcenter"),"value":_vm.totais_callcenter,"icon":"headset","color":"warning"}}),_c('CardBorderWidget',{attrs:{"title":(_vm.percentual_forca_vendas + " via FV"),"value":_vm.totais_forca_vendas,"icon":"user-tie","color":"primary"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
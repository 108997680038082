<template>
  <div :class="`card border-${color}`">
    <div class="card-body d-flex justify-content-between">
      <div>
        <div><strong>{{ value }}</strong></div>
        <div>{{ title }}</div>
      </div>
      <div>
        <fa-icon :icon="icon" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBorderWidget.vue",
  props: [
    "title",
    "value",
    "icon",
    "color"
  ],
}
</script>

<style scoped>
  .card {
    margin-bottom: 8px;
    border-width: 0 0 0 4px !important;
  }
</style>
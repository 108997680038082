<template>
  <div :class="`card text-white bg-${color}`">
    <div class="card-body d-flex justify-content-between">
      <div>
        <div class="text-value-lg">{{ value }}</div>
        <div>{{ title }}</div>
      </div>
      <div>
        <fa-icon :icon="icon" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardInfoWidget",
  props: [
    "title",
    "value",
    "icon",
    "color"
  ],
}
</script>

<style scoped>
  .card {
    margin-bottom: 8px;
  }
</style>
<template>
    <router-link class="router" to="/configuracoes/logs">
        <div class="aviso" v-if="percentual_uso >= 90">
            <p>Alerta: Espaço em disco livre está muito baixo! Percentual de uso: {{ percentual_uso }} %</p>
        </div>
    </router-link>
</template>

<script>
    export default{
        name: "AvisoArmazenamento",
        props: {percentual_uso: Number}
    }
</script>

<style scoped>
    .router{
        width: 100%;
        display: flex;
        justify-content: center;
        text-decoration: none;
        margin-bottom: 25px;
    }
    .aviso{
        color: #957333;
        font-size: larger;
        background-color: #feefd0;
        border-radius: 5px;
        height: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-decoration: none;
        padding-left: 19px;
    }
    .aviso p{
        margin-bottom: 0px;
    }
    .aviso:hover{
        background-color: #F0E1C5;
    }
</style>
<template>

    <div class="row" style="margin-bottom: 0.5em">
        <div class="col-sm-3">
          <CardInfoWidget
            title="Usuários Ativos"
            :value="metricas.qtd_usuarios"
            icon="user"
            color="primary"
          />
        </div>
        <div class="col-sm-3">
          <CardInfoWidget
            title="Clientes Ativos"
            :value="metricas.qtd_clientes"
            icon="users"
            color="info"
          />
        </div>
        <div class="col-sm-3">
          <CardInfoWidget
            title="Produtos Ativos"
            :value="metricas.qtd_produtos"
            icon="shopping-basket"
            color="warning"
          />
        </div>
        <div class="col-sm-3">
          <CardInfoWidget
            title="Pedidos já transm."
            :value="metricas.qtd_pedidos"
            icon="file-invoice-dollar"
            color="danger"
          />
        </div>
    </div>
</template>

<script>
  import CardInfoWidget from "../../../components/charts/CardInfoWidget";

  export default {
    name: "MetricasCadastros",
    components: {
      CardInfoWidget
    },
    props: [
      'metricas'
    ],
  }
</script>

<style scoped>

</style>